import { HttpClient } from '@angular/common/http'
import { Component, signal } from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'

import { ReCaptchaV3Service } from 'ng-recaptcha'
import { lastValueFrom } from 'rxjs'

import { ButtonComponent } from '@app/components/button/button.component'
import { ErrorMessageComponent } from '@app/components/error-message/error-message.component'
import { InputComponent } from '@app/components/input/input.component'
import { SectionTitleComponent } from '@app/components/section-title/section-title.component'
import { TextareaComponent } from '@app/components/textarea/textarea.component'
import { ScrollRevealDirective } from '@app/directives/scrollreveal.directive'
import { TranslateModule } from '@ngx-translate/core'
import { HotToastService } from '@ngxpert/hot-toast'

@Component({
  selector: 'app-home-contact-me',
  standalone: true,
  templateUrl: './contact-me.component.html',
  imports: [
    TranslateModule,
    InputComponent,
    ButtonComponent,
    TextareaComponent,
    SectionTitleComponent,
    ErrorMessageComponent,
    ReactiveFormsModule,
    ScrollRevealDirective,
  ],
  providers: [ReCaptchaV3Service],
})
export class ContactMeComponent {
  constructor(
    private readonly recaptchaV3Service: ReCaptchaV3Service,
    private readonly httpClient: HttpClient,
    private readonly toastService: HotToastService,
  ) {}

  loading = signal(false)

  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', [
      Validators.required,
      Validators.minLength(30),
      Validators.maxLength(500),
    ]),
  })

  async onSubmit(): Promise<void> {
    this.loading.set(true)

    try {
      const token = await lastValueFrom(
        this.recaptchaV3Service.execute('importantAction'),
      )

      const response = await lastValueFrom(
        this.httpClient.post<{ message: string }>('/sendMessage', {
          ...this.form.value,
          recaptcha: token,
        }),
      )

      this.toastService.success(response.message)
      this.form.reset()
    } finally {
      this.loading.set(false)
    }
  }
}
