import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http'
import { inject } from '@angular/core'

import { catchError, throwError } from 'rxjs'

import { TranslateService } from '@ngx-translate/core'
import { HotToastService } from '@ngxpert/hot-toast'

export const responseInterceptor: HttpInterceptorFn = (request, next) => {
  const toastService = inject(HotToastService)
  const translate = inject(TranslateService)

  return next(request).pipe(
    catchError(({ error }: HttpErrorResponse) => {
      translate.get('common.unexpected_error').subscribe(default_message => {
        const error_message: string = error.message ?? default_message

        toastService.error(error_message)
      })

      return throwError(() => error)
    }),
  )
}
