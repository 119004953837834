<div class="text-white">
  <p class="font-bold text-xl text-blue-main">{{project().name}}</p>
  <p class="font-semibold text-lg">
    <span>{{'common.from' | translate}}</span> {{project().by}}
  </p>

  <p class="mt-2 font-normal">{{project().description[locale]}}</p>

  @if (project().links.length >= 1) {
    <div class="mt-2 flex items-center space-x-2">
      @for (link of project().links; track link.id) {
        <a
          href={{link.uri}}
          target="_blank"
          class="cursor-pointer"
          [ariaLabel]="link.id"
        >
          <ng-icon name={{link.icon}} class="text-2xl"></ng-icon>
        </a>
      }
    </div>
  }
</div>
