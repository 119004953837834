<section scrollreveal class="w-screen max-w-7xl mx-auto flex flex-col justify-center px-2 md:px-6 py-32">
  <app-section-title [title]="'pages.home.sections.contact_me.title' | translate" />

  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="w-full max-w-2xl mt-12">
    <div class="mb-4">
      <app-input
        [label]="'common.fields.name' | translate"
        name="name"
        formControlName="name"
      />

      <app-error-message
        [show_message]="!!(form.get('name')?.hasError('required') && form.get('name')?.touched)"
        [message]="'common.validations.name.required' | translate"
      />
    </div>

    <div class="mb-4">
      <app-input
        [label]="'common.fields.email' | translate"
        name="email"
        type="email"
        formControlName="email"
      />

      <app-error-message
        [show_message]="!!(form.get('email')?.hasError('required') && form.get('email')?.touched)"
        [message]="'common.validations.email.required' | translate"
      />
      <app-error-message
        [show_message]="!!(form.get('email')?.hasError('email') && form.get('email')?.touched)"
        [message]="'common.validations.name.invalid' | translate"
      />
    </div>

    <div class="mb-16">
      <app-textarea
        [label]="'common.fields.message' | translate"
        name="message"
        class="h-32"
        formControlName="message"
        maxlength="500"
      />

      <app-error-message
        [show_message]="!!(form.get('message')?.hasError('required') && form.get('message')?.touched)"
        [message]="'common.validations.message.required' | translate"
      />

      <app-error-message
        [show_message]="!!(form.get('message')?.hasError('minlength') && form.get('message')?.touched)"
        [message]="'common.validations.message.minlength' | translate"
      />

      <app-error-message
        [show_message]="!!(form.get('message')?.hasError('maxlength') && form.get('message')?.touched)"
        [message]="'common.validations.message.maxlength' | translate"
      />
    </div>

    <app-button
      [label]="'common.send' | translate"
      type="submit"
      class="max-w-64"
      [loading]="loading()"
      [disabled]="!(form.dirty && form.valid)"
    />
  </form>
</section>
