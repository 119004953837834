import {
  viewChild,
  forwardRef,
  Component,
  ElementRef,
  Input,
} from '@angular/core'
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms'

@Component({
  selector: 'app-textarea',
  standalone: true,
  templateUrl: './textarea.component.html',
  imports: [FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent implements ControlValueAccessor {
  value: string = ''

  disabled = false

  onChange: any = (value: any) => {}

  onTouched: any = () => {}

  @Input({ required: true })
  label: string = ''

  @Input({ required: true })
  name: string = ''

  @Input()
  type: string = 'text'

  @Input()
  placeholder: string = ''

  @Input()
  class: string = ''

  @Input({ required: false })
  maxlength = '200'

  textarea_ref = viewChild<ElementRef<TextareaComponent>>('textarea')
  textarea_length = this.textarea_ref()?.nativeElement.value.length ?? 0

  writeValue(obj: any): void {
    this.value = obj
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  setDisabledState?(is_disabled: boolean): void {
    this.disabled = is_disabled
  }
}
