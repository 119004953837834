import { bootstrapApplication } from '@angular/platform-browser'

import { environment } from 'environments/environment'

import * as Sentry from '@sentry/angular'

import { AppComponent } from './app/app.component'
import { appConfig } from './app/app.config'

Sentry.init({
  dsn: environment.NG_SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

bootstrapApplication(AppComponent, appConfig).catch(error => {
  console.error(error)
})
