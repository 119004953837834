import { Component, input } from '@angular/core'

import { SupportedLanguages } from '@app/types/i18n'
import { IProject } from '@app/types/projects'
import { NgIconComponent, provideIcons } from '@ng-icons/core'
import { hugeApple, hugeLink01, hugeAndroid } from '@ng-icons/huge-icons'
import { TranslateModule, TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'project-item',
  standalone: true,
  templateUrl: './project.component.html',
  imports: [TranslateModule, NgIconComponent],
  viewProviders: [
    provideIcons({
      hugeApple,
      hugeLink01,
      hugeAndroid,
    }),
  ],
})
export class ProjectComponent {
  project = input.required<IProject>()
  locale: SupportedLanguages

  constructor(private readonly translate: TranslateService) {
    this.locale = this.translate.currentLang as SupportedLanguages

    this.translate.onLangChange.subscribe(({ lang }) => {
      this.locale = lang as SupportedLanguages
    })
  }
}
