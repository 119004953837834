import {
  animate,
  AnimationBuilder,
  AnimationPlayer,
  style,
} from '@angular/animations'
import {
  afterNextRender,
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core'

import { Subject } from 'rxjs'

type Position = 'left' | 'top' | 'right' | 'bottom'
type AnimationType = 'appear' | 'desappear'
type Animation = Record<AnimationType, Record<string, any>>

@Directive({
  selector: '[scrollreveal]',
  standalone: true,
})
export class ScrollRevealDirective implements OnDestroy, AfterViewInit {
  @Input('position')
  position: Position = 'left'

  @Input()
  duration: number = 300

  @Input()
  delay: number = 0

  @Input()
  easing: '' | 'ease' | 'ease-in' | 'ease-out' | 'ease-in-out' = ''

  @Input()
  threshold: number = 0.6

  @Input()
  root_margin: string = '0px'

  @Output()
  intersection = new EventEmitter<HTMLElement>()

  animation_player: AnimationPlayer | undefined = undefined

  private observer$: IntersectionObserver | null = null

  private readonly intersect$ = new Subject<void>()

  private readonly animations_common = {
    desappear: {
      opacity: '0',
    },
    appear: {
      opacity: '1',
    },
  }

  private readonly animations: Record<Position, Animation> = {
    left: {
      desappear: {
        ...this.animations_common.desappear,
        transform: 'translateX(-4rem)',
      },
      appear: {
        ...this.animations_common.appear,
        transform: 'translateX(0)',
      },
    },
    right: {
      desappear: {
        ...this.animations_common.desappear,
        transform: 'translateX(4rem)',
      },
      appear: {
        ...this.animations_common.appear,
        transform: 'translateX(0)',
      },
    },
    top: {
      desappear: {
        ...this.animations_common.desappear,
        transform: 'translateY(-4rem)',
      },
      appear: {
        ...this.animations_common.appear,
        transform: 'translateY(0)',
      },
    },
    bottom: {
      desappear: {
        ...this.animations_common.desappear,
        transform: 'translateY(4rem)',
      },
      appear: {
        ...this.animations_common.appear,
        transform: 'translateY(0)',
      },
    },
  }

  constructor(
    private readonly element: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
    private readonly builder: AnimationBuilder,
  ) {
    afterNextRender(() => {
      this.setHideStyles()
      this.createObserver()
      this.createAnimation()
      this.startObserving()
    })
  }

  ngAfterViewInit(): void {
    this.intersect$.subscribe(() => {
      const target = this.element.nativeElement
      this.animation_player?.play()
      this.intersection.emit(this.element.nativeElement)
      this.observer$?.unobserve(target)
    })
  }

  createObserver(): void {
    const options = {
      root: null,
      rootMargin: this.root_margin,
      threshold: this.threshold,
    }

    this.observer$ = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.intersectionRatio >= this.threshold) {
          this.intersect$.next()
        }
      })
    }, options)
  }

  setHideStyles(): void {
    const animation = this.animations[this.position].desappear
    for (const style of Object.keys(animation)) {
      this.renderer.setStyle(
        this.element.nativeElement,
        style,
        animation[style],
      )
    }
  }

  createAnimation(): void {
    const animation = this.animations[this.position].appear
    const factory = this.builder.build([
      animate(
        `${this.duration}ms ${this.delay}ms ${this.easing}`.trim(),
        style(animation),
      ),
    ])
    this.animation_player = factory.create(this.element.nativeElement)
  }

  startObserving(): void {
    this.observer$?.observe(this.element.nativeElement)
  }

  ngOnDestroy(): void {
    if (this.observer$ !== null) {
      this.observer$.disconnect()
      this.observer$ = null
    }
    this.intersect$.complete()
  }
}
