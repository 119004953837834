import { HttpInterceptorFn } from '@angular/common/http'
import { inject, LOCALE_ID } from '@angular/core'

import { environment } from 'environments/environment'

export const requestInterceptor: HttpInterceptorFn = (request, next) => {
  const locale = inject(LOCALE_ID)

  const request_cloned = request.clone({
    url: `${environment.NG_APP_BASE_URI}${request.url}`,
    setHeaders: {
      'accept-language': locale,
    },
  })

  return next(request_cloned)
}
