import { Component } from '@angular/core'

import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './not-found.page.html',
})
export class NotFoundPage {}
