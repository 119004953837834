<section class="w-screen max-w-7xl mx-auto flex flex-col justify-center px-2 md:px-6 py-32">
  <app-section-title scrollreveal [title]="'pages.home.sections.projects.title' | translate" />

  @if (projects()) {
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-y-6 lg:gap-x-6 mt-12">
      @for (project of projects(); track project.id) {
        <project-item
          scrollreveal
          [delay]="animation_delay * $index"
          [position]="$index % 2 === 0 ? 'left' : 'right'"
          [project]="project"
        />
      }
    </div>
  }
</section>
