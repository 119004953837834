export const environment = {
  NG_APP_BASE_URI: 'https://us-central1-resume-c9bb5.cloudfunctions.net',
  NG_APP_CV_URI: 'https://storage.googleapis.com/resume_main/Iury%20Brasileiro%20-%20Resume.pdf',
  NG_APP_GITHUB_URI: 'https://github.com/iurybrasileiro',
  NG_APP_LINKEDIN_URI: 'https://www.linkedin.com/in/iury-brasileiro-maia/',
  NG_APP_WHATSAPP_URI: 'https://wa.me/5511932053615',
  NG_APP_FIREBASE_API_KEY: 'AIzaSyAJ5G4nBk-s1Ai3-SYKll2JoRDplfmzzHM',
  NG_APP_FIREBASE_AUTH_DOMAIN: 'resume-c9bb5.firebaseapp.com',
  NG_APP_FIREBASE_PROJECT_ID: 'resume-c9bb5',
  NG_APP_FIREBASE_STORAGE_BUCKET: 'resume-c9bb5.firebasestorage.app',
  NG_APP_FIREBASE_MESSAGING_SENDER_ID: '631445522764',
  NG_APP_FIREBASE_APP_ID: '1:631445522764:web:77d298d28eb334a056e7e9',
  NG_APP_FIREBASE_MEASUREMENT_ID: 'G-RR8VMC3D36',
  NG_RECAPTCHA_CLIENT_KEY: '6LeI0Q8qAAAAAH-HhliFyb-w4_KZHvVOu-K8ZE7j',
  NG_SENTRY_DNS: 'https://3464ed17a8124391afb3d4d44183a919@o399502.ingest.us.sentry.io/4504386907537408',
}
