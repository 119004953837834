export default {
  from: 'From',
  send: 'Send',
  english: 'English',
  portuguese: 'Portuguese',
  fields: {
    name: 'Name',
    email: 'Email',
    message: 'Message',
  },
  validations: {
    name: {
      required: 'The name is required',
    },
    email: {
      required: 'The email is required',
      invalid: 'The email is invalid',
    },
    message: {
      required: 'The message is required',
      minlength: 'The message should contains at least 30 characters',
      maxlength: 'The message must contain a maximum of 500 characters',
    },
  },
  unexpected_error: 'We had an unexpected error',
}
