<nav class="fixed w-full z-20 top-0 start-0 bg-black">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
    <div href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
      <img src="/favicon.ico" class="size-8" alt="Logo" />
      <span class="self-center text-2xl font-semibold whitespace-nowrap text-white">Iury Brasileiro</span>
    </div>

    <div class="w-36 flex items-center rtl:space-x-reverse relative">
      @if (current_language(); as language) {
        <button #dropdown_button type="button" (click)="toggleLanguageDropdown()" class="w-full bg-slate-700 inline-flex items-center font-medium px-4 py-2 text-sm text-white rounded-lg cursor-pointer">
          <img [src]="language.image" class="w-5 h-5 rounded-full mr-3" />
          {{"common."+language.label | translate}}
        </button>
      }

      <div #dropdown [class]="getDropdownClass()">
        <ul class="py-2 font-medium">
          @for (language of languages_options; track language.id) {
            <li class="w-full">
              <button type="button" (click)="changeLanguage(language.id)" class="w-full block px-4 py-1 text-sm text-white hover:bg-slate-600 text-left">
                <div class="inline-flex mt-1">
                  <img [src]="language.image" class="h-5 w-5 rounded-full mr-3" [alt]="language.label" />

                  {{"common."+language.label | translate}}
                </div>
              </button>
            </li>
          }
        </ul>
      </div>
    </div>
  </div>
</nav>
