<section class="w-screen max-w-7xl mx-auto flex flex-col justify-center px-2 md:px-6 py-32">
  <app-section-title scrollreveal [title]="'pages.home.sections.technologies.title' | translate" />

  <div class="mt-12 grid gap-12 grid-cols-4 lg:grid-cols-6">
    @for (technology of technologies(); track technology.id;) {
      <technology-item
        scrollreveal
        [delay]="animation_delay * $index"
        [position]="'bottom'"
        [technology]="technology"
      />
    }
  </div>
</section>
