<div>
  @if (label) {
    <label
      [for]="name"
      class="block mb-2 text-sm font-medium text-white"
    >
      {{label}}
    </label>
  }

  <input
    [(ngModel)]="value"
    [type]="type"
    [id]="name"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [class]="getClass()"
    (input)="onChange(value)"
    (blur)="onTouched()"
  />
</div>
