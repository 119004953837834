import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http'
import {
  APP_INITIALIZER,
  ErrorHandler,
  importProvidersFrom,
  provideZoneChangeDetection,
  type ApplicationConfig,
} from '@angular/core'
import {
  getAnalytics,
  provideAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics'
import { provideFirebaseApp, initializeApp } from '@angular/fire/app'
import { getFirestore, provideFirestore } from '@angular/fire/firestore'
import { provideClientHydration } from '@angular/platform-browser'
import { provideAnimations } from '@angular/platform-browser/animations'
import { provideRouter, Router } from '@angular/router'

import { environment } from 'environments/environment'
import {
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaFormsModule,
  RecaptchaV3Module,
} from 'ng-recaptcha'

import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { provideHotToastConfig } from '@ngxpert/hot-toast'
import * as Sentry from '@sentry/angular'

import { routes } from './app.routes'
import { LocalLoader } from './i18n/local.loader'
import { requestInterceptor } from './interceptors/request.interceptor'
import { responseInterceptor } from './interceptors/response.interceptor'

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimations(),
    provideClientHydration(),
    provideFirebaseApp(() =>
      initializeApp({
        apiKey: environment.NG_APP_FIREBASE_API_KEY,
        authDomain: environment.NG_APP_FIREBASE_AUTH_DOMAIN,
        projectId: environment.NG_APP_FIREBASE_PROJECT_ID,
        storageBucket: environment.NG_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: environment.NG_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: environment.NG_APP_FIREBASE_APP_ID,
        measurementId: environment.NG_APP_FIREBASE_MEASUREMENT_ID,
      }),
    ),
    provideFirestore(() => getFirestore()),
    provideAnalytics(() => getAnalytics()),
    importProvidersFrom(RecaptchaV3Module, RecaptchaFormsModule),
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.NG_RECAPTCHA_CLIENT_KEY,
    },
    provideHotToastConfig({
      autoClose: true,
      theme: 'toast',
      position: 'top-right',
      dismissible: true,
    }),
    provideHttpClient(
      withInterceptors([requestInterceptor, responseInterceptor]),
      withFetch(),
    ),
    importProvidersFrom([
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: LocalLoader,
        },
      }),
    ]),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    ScreenTrackingService,
    UserTrackingService,
  ],
}
