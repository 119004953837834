<section class="w-screen max-w-7xl mx-auto flex flex-col justify-center px-2 md:px-6 py-32">
  <app-section-title
    scrollreveal
    [title]="'pages.home.sections.about_me.title' | translate"
  />

  <div scrollreveal [delay]="100" class="mt-12 text-white">
    <p>{{ "pages.home.sections.about_me.text" | translate }}</p>
  </div>
</section>
