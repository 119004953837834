export default {
  sections: {
    main: {
      title: 'Hi, folks',
      subtitle: "I'm Iury Brasileiro",
      role: "I'm Fullstack developer",
      cta: 'Download CV',
    },
    about_me: {
      title: 'About me',
      text: 'I am a Full Stack Developer experienced in delivering high-quality products using a variety of tools and frameworks. At Fluyo.inc, I streamlined workflows with GitHub Actions and introduced project structures that improved code organisation and testing. My expertise extends beyond JavaScript, with notable contributions in backend migrations and performance optimization at Cyberangel and Doctor Web Brasil. I am committed to continuous learning, always aiming to deliver the best solutions, regardless of the technology stack.',
    },
    projects: {
      title: 'Projects',
    },
    technologies: {
      title: 'Technologies',
    },
    contact_me: {
      title: 'Contact me',
    },
  },
  footer: 'Thank you for visiting me 😉',
}
