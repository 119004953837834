import { inject, Injectable } from '@angular/core'
import { collection, collectionData, Firestore } from '@angular/fire/firestore'

import * as _ from 'ramda'
import { map, Observable } from 'rxjs'

import { IProject } from '@app/types/projects'
import { ITechnology } from '@app/types/technologies'

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  firestore: Firestore = inject(Firestore)

  loadProjects(): Observable<IProject[]> {
    const projects_ref = collection(this.firestore, 'projects')
    return collectionData(projects_ref).pipe(
      map((snapshot: IProject[]) => {
        const projects = _.sortBy(_.prop('order'))(snapshot)
        return projects
      }),
    )
  }

  loadTechnologies(): Observable<ITechnology[]> {
    const technologies_ref = collection(this.firestore, 'technologies')
    return collectionData(technologies_ref).pipe(
      map((snapshot: ITechnology[]) => {
        const technologies = _.sortBy(_.prop('order'))(snapshot)
        return technologies
      }),
    )
  }
}
