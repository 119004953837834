import { Component } from '@angular/core'

import { environment } from 'environments/environment'

import { NgIconComponent, provideIcons } from '@ng-icons/core'
import {
  hugeGithub,
  hugeWhatsapp,
  hugeLinkedin01,
  hugeDownload02,
} from '@ng-icons/huge-icons'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'app-home-main',
  standalone: true,
  templateUrl: './main.component.html',
  imports: [TranslateModule, NgIconComponent],
  viewProviders: [
    provideIcons({
      hugeGithub,
      hugeWhatsapp,
      hugeLinkedin01,
      hugeDownload02,
    }),
  ],
})
export class MainComponent {
  CV_URI = environment.NG_APP_CV_URI
  GITHUB_URI = environment.NG_APP_GITHUB_URI
  LINKEDIN_URI = environment.NG_APP_LINKEDIN_URI
  WHATSAPP_URI = environment.NG_APP_WHATSAPP_URI
}
