import { Component, Signal } from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'

import { SectionTitleComponent } from '@app/components/section-title/section-title.component'
import { ScrollRevealDirective } from '@app/directives/scrollreveal.directive'
import { FirebaseService } from '@app/services/firebase.service'
import { ITechnology } from '@app/types/technologies'
import { TranslateModule } from '@ngx-translate/core'

import { TechnologyComponent } from './components/technology/technology.component'

@Component({
  selector: 'app-home-technologies',
  standalone: true,
  templateUrl: './technologies.component.html',
  imports: [
    TranslateModule,
    TechnologyComponent,
    SectionTitleComponent,
    ScrollRevealDirective,
  ],
})
export class TechnologiesComponent {
  technologies: Signal<ITechnology[]>

  animation_delay = 50

  constructor(private readonly firebaseService: FirebaseService) {
    const technologies$ = this.firebaseService.loadTechnologies()
    this.technologies = toSignal(technologies$, { initialValue: [] })
  }
}
