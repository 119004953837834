import { Component } from '@angular/core'

import { TranslateModule } from '@ngx-translate/core'

import { AboutComponent } from './components/about/about.component'
import { ContactMeComponent } from './components/contact-me/contact-me.component'
import { MainComponent } from './components/main/main.component'
import { ProjectsComponent } from './components/projects/projects.component'
import { TechnologiesComponent } from './components/technologies/technologies.component'

@Component({
  selector: 'app-home',
  standalone: true,
  templateUrl: './home.page.html',
  imports: [
    TranslateModule,
    MainComponent,
    AboutComponent,
    ProjectsComponent,
    TechnologiesComponent,
    ContactMeComponent,
  ],
})
export class HomePage {}
