<section class="w-screen max-w-7xl mx-auto min-h-screen flex items-center px-2 md:px-6 text-white">
  <div class="flex flex-1 flex-col items-center lg:items-start mt-12 lg:mx-0">
    <p class="text-2xl lg:text-4xl font-bold text-blue-main">
      {{"pages.home.sections.main.title" | translate}}
    </p>
    <h1 class="text-4xl lg:text-6xl font-black text-purple-main">
      {{"pages.home.sections.main.subtitle" | translate}}
    </h1>
    <h2 class="text-2xl lg:text-4xl mt-4 font-medium">
      {{"pages.home.sections.main.role" | translate}}
    </h2>

    <div class="flex flex-row items-center mt-8">
      <a
        download
        href={{CV_URI}}
        target="_blank"
        class="w-52 lg:w-64 flex flex-row justify-center items-center rounded-full h-12 py-2.5 px-5 bg-purple-main hover:bg-purple-main-darken-5% border-0 text-white font-bold transition-all duration-200"
        aria-label="Download CV"
      >
        <ng-icon name="hugeDownload02" class="text-2xl mr-4" />
        <span>{{"pages.home.sections.main.cta" | translate}}</span>
      </a>

      <div class="mt-2">
        <a target="_blank" class="ml-6 lg:ml-8" href={{GITHUB_URI}} aria-label="Github">
          <ng-icon name="hugeGithub" class="text-3xl" />
        </a>

        <a target="_blank" class="ml-4 lg:ml-6" href={{LINKEDIN_URI}} aria-label="LinkedIn">
          <ng-icon name="hugeLinkedin01" class="text-3xl" />
        </a>

        <a target="_blank" class="ml-4 lg:ml-6" href={{WHATSAPP_URI}} aria-label="Whatsapp">
          <ng-icon name="hugeWhatsapp" class="text-3xl" />
        </a>
      </div>
    </div>
  </div>
</section>
