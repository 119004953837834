<div>
  @if (label) {
    <label
      [for]="name"
      class="block mb-2 text-sm font-medium text-white"
    >
      {{label}}
    </label>
  }

  <div [class]="class">
    <textarea
      #textarea
      [(ngModel)]="value"
      [id]="name"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [maxlength]="maxlength"
      class="bg-white-vanilla border border-slate-200 text-gray-900 rounded block w-full p-2 outline-none focus:border-pink-main disabled:bg-gray-200 resize-none h-full"
      (input)="onChange(value)"
      (blur)="onTouched()"
    ></textarea>
    <p class="text-right text-white text-sm mt-0.5">{{textarea_length}}/{{maxlength}}</p>
  </div>
</div>
