import { Component, input } from '@angular/core'

import { ITechnology } from '@app/types/technologies'

@Component({
  selector: 'technology-item',
  standalone: true,
  templateUrl: './technology.component.html',
})
export class TechnologyComponent {
  technology = input.required<ITechnology>()
}
