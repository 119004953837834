export default {
  sections: {
    main: {
      title: 'Olá, pessoal',
      subtitle: 'Eu sou Iury Brasileiro',
      role: 'Sou desenvolvedor Fullstack',
      cta: 'Baixar CV',
    },
    about_me: {
      title: 'Sobre mim',
      text: 'Sou um desenvolvedor Full Stack com experiência em fornecer produtos de alta qualidade usando uma variedade de ferramentas e estruturas. Na Fluyo.inc, simplifiquei os fluxos de trabalho com o GitHub Actions e introduzi estruturas de projeto que melhoraram a organização e os testes de código. Minha experiência vai além do JavaScript, com contribuições notáveis em migrações de back-end e otimização de desempenho na Cyberangel e na Doctor Web Brasil. Estou comprometido com o aprendizado contínuo, sempre com o objetivo de oferecer as melhores soluções, independentemente da tecnologia.',
    },
    projects: {
      title: 'Projetos',
    },
    technologies: {
      title: 'Tecnologias',
    },
    contact_me: {
      title: 'Entre em contato',
    },
  },
  footer: 'Obrigado por me visitar 😉',
}
