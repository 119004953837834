import {
  signal,
  viewChild,
  Component,
  ElementRef,
  HostListener,
  Output,
} from '@angular/core'

import { twMerge } from 'tailwind-merge'

import { languages } from '@app/constans/language'
import { LanguageOption, SupportedLanguages } from '@app/types/i18n'
import { TranslateModule, TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  imports: [TranslateModule],
})
export class HeaderComponent {
  current_language = signal<LanguageOption | undefined>(undefined)

  is_language_dropdown_opened = signal(false)

  languages_options = languages

  dropdown_button_ref =
    viewChild<ElementRef<HTMLButtonElement>>('dropdown_button')

  dropdown_ref = viewChild<ElementRef<HTMLDivElement>>('dropdown')

  constructor(private readonly translate: TranslateService) {
    this.current_language.set(this.getCurrentLanguageOption())
  }

  @Output()
  closeLanguageOptions(): void {
    if (this.is_language_dropdown_opened()) {
      this.is_language_dropdown_opened.set(false)
    }
  }

  @HostListener('document:click', ['$event.target'])
  documentClick(target: Node): void {
    if (
      target !== null &&
      target !== this.dropdown_button_ref()?.nativeElement &&
      !(this.dropdown_ref()?.nativeElement.contains(target) ?? false)
    ) {
      this.is_language_dropdown_opened.set(false)
    }
  }

  getCurrentLanguageOption(): LanguageOption {
    const language_id = this.translate.currentLang
    const language = languages.find(language => language.id === language_id)
    return language!
  }

  toggleLanguageDropdown(): void {
    this.is_language_dropdown_opened.update(prev => !prev)
  }

  getDropdownClass(): string {
    const default_classes =
      'w-full hidden absolute z-50 top-11 text-base list-none bg-white rounded-lg shadow bg-slate-700'

    return twMerge(
      default_classes,
      this.is_language_dropdown_opened() && 'block',
    )
  }

  changeLanguage(language: SupportedLanguages): void {
    this.translate.use(language)
    this.is_language_dropdown_opened.set(false)
    this.current_language.set(this.getCurrentLanguageOption())
  }
}
