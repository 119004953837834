import { Observable, of } from 'rxjs'

import { SupportedLanguages } from '@app/types/i18n'
import { TranslateLoader, TranslationObject } from '@ngx-translate/core'

import resources from './resources'

export class LocalLoader implements TranslateLoader {
  getTranslation(lang: SupportedLanguages): Observable<TranslationObject> {
    return of(resources[lang])
  }
}
