import { Component } from '@angular/core'

import { SectionTitleComponent } from '@app/components/section-title/section-title.component'
import { ScrollRevealDirective } from '@app/directives/scrollreveal.directive'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'app-home-about',
  standalone: true,
  templateUrl: './about.component.html',
  imports: [TranslateModule, SectionTitleComponent, ScrollRevealDirective],
})
export class AboutComponent {}
