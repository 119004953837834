import { Component, viewChild } from '@angular/core'
import { RouterOutlet } from '@angular/router'

import { TranslateService } from '@ngx-translate/core'

import { HeaderComponent } from './components/header/header.component'
import { default_language, supported_languages } from './constans/language'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent],
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(private readonly translate: TranslateService) {
    this.translate.addLangs(Array.from(supported_languages))
    this.translate.setDefaultLang(default_language)

    const language = this.translate.getBrowserLang() ?? default_language
    this.translate.use(language)
  }

  header_ref = viewChild<HeaderComponent>(HeaderComponent)

  onScroll(): void {
    this.header_ref()?.closeLanguageOptions()
  }
}
