import { LanguageOption } from '@app/types/i18n'

export const default_language = 'en'

export const supported_languages = [default_language, 'pt'] as const

export const languages: LanguageOption[] = [
  {
    id: 'en',
    label: 'english',
    image: '/united-states.svg',
  },
  {
    id: 'pt',
    label: 'portuguese',
    image: '/brazil.svg',
  },
]
